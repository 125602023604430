// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-b-2-b-ecommerce-js": () => import("./../../../src/pages/b2b_ecommerce.js" /* webpackChunkName: "component---src-pages-b-2-b-ecommerce-js" */),
  "component---src-pages-our-team-js": () => import("./../../../src/pages/our-team.js" /* webpackChunkName: "component---src-pages-our-team-js" */),
  "component---src-pages-wp-page-link-js": () => import("./../../../src/pages/{wpPage.link}.js" /* webpackChunkName: "component---src-pages-wp-page-link-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-products-posts-js": () => import("./../../../src/templates/products-posts.js" /* webpackChunkName: "component---src-templates-products-posts-js" */),
  "component---src-templates-services-post-js": () => import("./../../../src/templates/services-post.js" /* webpackChunkName: "component---src-templates-services-post-js" */)
}

